<template>
  <Loading v-if="isLoading" :active="isLoading" />
  <CBox
    v-else-if="!isLoading && errorMessage !== 'club not found'"
    boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
    borderRadius="5"
    w="100%"
    h="fit-content"
    my="16px"
    bg="white"
    p="24px"
  >
    <CBox>
      <CText fontSize="16px" fontWeight="600"> Total Join Track </CText>
      <CText fontSize="22px" fontWeight="600">
        {{ total_join }}
      </CText>
    </CBox>
    <CBox mt="4">
      <CText fontSize="16px" fontWeight="600"> Share This Track </CText>
      <CButton
        :bg="!isUrlCopied ? 'white' : 'main'"
        :color="!isUrlCopied ? 'main' : 'white'"
        :border="!isUrlCopied ? '#29AAFE 1px solid' : ''"
        :_hover="!isUrlCopied ? { opacity: '0.8' } : { opacity: '1' }"
        my="2"
        size="0"
        w="fit-content"
        px="16px"
        py="8px"
        fontSize="12px"
        @click="copyUrl"
      >
        Share
      </CButton>
    </CBox>
    <CText
      v-if="!currentUser"
      color="main"
      :_hover="{ textDecoration: 'underline' }"
      cursor="pointer"
      fontSize="12px"
      rounded="md"
      d="flex"
      w="fit-content"
      mt="1"
      alignItems="center"
      fontWeight="600"
      @click.native="handleLogin"
    >
      Login
    </CText>
  </CBox>
</template>

<script>
import Loading from "vue-loading-overlay";
import { CBox, CText } from "@chakra-ui/vue";
import config from "@/ConfigProvider.js";
import Swal from "sweetalert2";

export default {
  components: {
    Loading,
    CBox,
    CText,
  },
  props: {
    total_join: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isLoading: true,
      isUrlCopied: false,
      isModalOpen: false,
      urlGethired: config.value("GETHIRED_WEB_URL"),
    };
  },
  async mounted() {
    try {
      await this.getData();
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    handleLogin() {
      window.location.href =
        config.value("GETHIRED_WEB_URL") +
        `/signin?next=${window.location.href}`;
    },
    copyUrl() {
      const inviteUrl = window.location;
      const dummyInput = document.createElement("input");
      document.body.appendChild(dummyInput);
      dummyInput.value = inviteUrl;
      dummyInput.select();
      document.execCommand("copy");
      document.body.removeChild(dummyInput);

      this.isUrlCopied = true;
      Swal.fire({
        title: "Copied",
        text: "Path " + "Belajar Javascript" + " url ready to share.",
        icon: "success",
      });
      setTimeout(() => {
        this.isUrlCopied = false;
      }, 2000);
    },
    openModal() {
      this.isModalOpen = true;
    },
    async closeModal() {
      this.isModalOpen = false;
    },
  },
  computed: {
    currentUser() {
      const user = this.$store.getters.currentUser;
      return user;
    },
  },
};
</script>
