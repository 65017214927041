<template>
  <CBox mt="10px">
    <Loading :active="isLoading" />
    <BackHeader />
    <CBox bg="white" p="24px" boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)">
      <CBox d="flex" justifyContent="space-between">
        <CText fontWeight="400" fontSize="12px">
          {{ "Track ".toUpperCase() + currentTrack.type.toUpperCase() }}
        </CText>
        <CBox
          :w="['150px', '200px', '200px', '200px']"
          d="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <CProgress color="vue" size="sm" :value="currentTrack.progress" />
          <CText ml="8px" fontSize="14px"
            >{{ currentTrack.progress.toFixed(2) }}%</CText
          >
        </CBox>
      </CBox>
      <CText fontWeight="600" fontSize="16px"> {{ currentTrack.name }} </CText>
      <CBox mt="12px">
        <CButton
          v-if="currentTrack.progress === 100"
          bg="bgButtonClub"
          color="white"
          size="0"
          :fontSize="['12px', '14px', '14px', '14px']"
          :p="['8px 10px', '10px 16px', '10px 16px', '10px 16px']"
          fontWeight="600"
          mr="8px"
          :_hover="{ opacity: '0.8' }"
          @click.native="scrollDown"
        >
          Explore Track
        </CButton>
        <CButton
          v-else
          :bg="
            currentTrack.progress === 100
              ? '#00A790'
              : currentTrack.progress > 0
              ? 'orange'
              : 'bgButtonClub'
          "
          color="white"
          size="0"
          :fontSize="['12px', '14px', '14px', '14px']"
          :p="['8px 10px', '10px 16px', '10px 16px', '10px 16px']"
          fontWeight="600"
          mr="8px"
          :_hover="{ opacity: '0.8' }"
          @click.native="
            $router.push(
              `${currentTrack.slug}/challenge/${checkSlug(
                currentTrack.app_types
              )}`
            )
          "
        >
          {{
            currentTrack.progress === 100
              ? "Completed"
              : currentTrack.progress > 0
              ? "On Progress"
              : "Start Track"
          }}
        </CButton>
        <CButton
          size="0"
          :bg="!isBookmark ? 'white' : 'main'"
          :color="!isBookmark ? 'main' : 'white'"
          :border="!isBookmark ? '#29AAFE 1px solid' : ''"
          :_hover="{ opacity: '0.8' }"
          :fontSize="['12px', '14px', '14px', '14px']"
          :p="['4px 10px', '8px 16px', '8px 16px', '8px 16px']"
          fontWeight="600"
          @click.native="isBookmark = !isBookmark"
        >
          <CImage
            :src="
              !isBookmark
                ? require('@/assets/icon/icon-bookmark-reguler.png')
                : require('@/assets/icon/icon-bookmark-solid.png')
            "
            w="18px"
            h="18px"
            mr="4px"
          />
          Bookmark
        </CButton>
      </CBox>
      <CBox d="flex" mt="12px" alignItems="center">
        <CBox
          bg="#00A790"
          color="white"
          w="80px"
          h="20px"
          borderRadius="34px"
          textAlign="center"
          mr="16px"
        >
          <CText
            fontWeight="600"
            :fontSize="['12px', '12px', '12px', '12px']"
            textTransform="capitalize"
          >
            {{ currentTrack.addtional_info.difficulty }}</CText
          >
        </CBox>
        <CBox d="flex" alignItems="center" mr="16px">
          <CImage
            :src="require('@/assets/icon/icon-clock.svg')"
            w="16px"
            h="16px"
            mr="4px"
          />
          <CText
            fontWeight="400"
            :fontSize="['12px', '12px', '12px', '12px']"
            textTransform="capitalize"
          >
            {{ currentTrack.addtional_info.estimated_time }}
            {{ currentTrack.addtional_info.time_unit.toLowerCase() }}</CText
          >
        </CBox>
        <CBox d="flex" alignItems="center" mr="16px">
          <CIcon color="main" name="book" fontSize="15px" mr="4px" />
          <CText
            fontWeight="400"
            :fontSize="['12px', '12px', '12px', '12px']"
            textTransform="capitalize"
          >
            {{ currentTrack.total_assesment }}
            Modules</CText
          >
        </CBox>
        <CBox
          v-if="currentTrack.addtional_info.is_certificate"
          d="flex"
          alignItems="center"
        >
          <CImage
            :src="require('@/assets/icon/icon-certificate.png')"
            w="20px"
            h="20px"
            mr="4px"
          />
          <CText
            fontWeight="400"
            :fontSize="['12px', '12px', '12px', '12px']"
            textTransform="capitalize"
          >
            Certivicate</CText
          >
        </CBox>
      </CBox>
    </CBox>
    <CBox d="flex" justifyContent="space-between" flexWrap="wrap">
      <CBox :w="['100%', '100%', '74%', '74%']">
        <CBox
          bg="white"
          p="24px"
          boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
          my="16px"
        >
          <CText fontWeight="600" mb="16px">Track Desctiption</CText>
          <CText fontWeight="400" fontSize="12px" white-space="pre-line">
            {{
              !showFullDescription &&
              currentTrack.description.length > maxDescriptionLength
                ? truncatedDescription
                : currentTrack.description
            }}
          </CText>
          <CBox d="flex" justifyContent="center">
            <CText
              v-if="
                !showFullDescription &&
                currentTrack.description.length > maxDescriptionLength
              "
              @click.native="showFullDescription = true"
              color="bgButtonClub"
              fontSize="14px"
              fontWeight="600"
              mt="8px"
              py="8px"
              cursor="pointer"
              :_hover="{ opacity: '0.8' }"
            >
              Read More
            </CText>
          </CBox>
        </CBox>
        <div ref="scrollTarget">
          <CBox
            v-for="(v, i) in currentTrack.app_types"
            :key="i"
            bg="white"
            p="24px"
            boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
            :mt="i !== 0 ? '16px' : '0'"
          >
            <CBox d="flex" alignItems="center">
              <CBox d="flex" mr="6px" alignItems="center">
                <CText
                  p="2px 8px"
                  mr="6px"
                  bg="#EFF1F5"
                  borderRadius="100%"
                  fontSize="12px"
                  fontWeight="600"
                  >{{ i + 1 }}</CText
                >
                <CText fontWeight="600" fontSize="14px">
                  {{ truncateText(v.app_type.name, 60) }}
                </CText>
              </CBox>
              <CBox
                :bg="v.app_type.status == 'available' ? '#EFF1F5' : '#00A790'"
                px="8px"
                py="2px"
                borderRadius="5px"
                textAlign="center"
                d="none"
              >
                <CText
                  fontWeight="600"
                  fontSize="12px"
                  :color="v.app_type.status == 'available' ? '' : 'white'"
                  textTransform="capitalize"
                  >{{ v.app_type.status }}</CText
                >
              </CBox>
              <CText
                v-if="v.xp"
                fontWeight="600"
                fontSize="12px"
                ml="auto"
                :d="['none', 'block', 'block', 'block']"
                >{{ v.xp }} XP</CText
              >
            </CBox>
            <CBox d="none" alignItems="center" mt="8px">
              <CBox
                :bg="v.app_type.status == 'available' ? '#EFF1F5' : '#00A790'"
                px="8px"
                py="2px"
                borderRadius="5px"
                textAlign="center"
                d="inline-block"
              >
                <CText
                  fontWeight="600"
                  fontSize="12px"
                  :color="v.app_type.status == 'available' ? '' : 'white'"
                  >{{ v.app_type.status }}</CText
                >
              </CBox>
              <CText v-if="v.xp" fontWeight="600" fontSize="12px" ml="8px"
                >{{ v.xp }} XP</CText
              >
            </CBox>
            <CBox
              borderBottom="1px solid var(--Neutral-Light-Gray, #EFF1F5)"
              py="16px"
            >
              <CText
                fontSize="12px"
                px="16px"
                v-html="
                  showDescAssesment.includes(i)
                    ? v.app_type.description
                    : truncateText(v.app_type.description, 500)
                "
              >
              </CText>
              <CBox d="flex" justifyContent="center">
                <CText
                  v-if="
                    !showDescAssesment.includes(i) &&
                    v.app_type.description.length > 200
                  "
                  @click.native="handleShowDescAssesment(i)"
                  color="bgButtonClub"
                  fontSize="14px"
                  fontWeight="600"
                  mt="8px"
                  py="8px"
                  cursor="pointer"
                  :_hover="{ opacity: '0.8' }"
                >
                  Read More
                </CText>
              </CBox>
            </CBox>
            <CBox d="flex" justifyContent="space-between" py="16px">
              <CBox
                d="flex"
                alignItems="center"
                cursor="pointer"
                @click.native="handleHideDetail(i)"
              >
                <CText
                  fontWeight="600"
                  :fontSize="['12px', '14px', '14px', '14px']"
                  color="bgButtonClub"
                >
                  {{ hideDetail.includes(i) ? "View" : "Hide" }} Class Details
                </CText>
                <CImage
                  :src="require('@/assets/icon/icon-park.svg')"
                  borderRadius="5"
                  w="16px"
                  h="16px"
                  ml="4px"
                  :class="hideDetail.includes(i) ? 'rotate-0' : 'rotate-180 '"
                />
              </CBox>
              <CButton
                :bg="v.app_type.status === 'done' ? '#00A790' : 'bgButtonClub'"
                color="white"
                size="0"
                fontSize="12px"
                :p="['8px 10px', '10px 16px', '10px 16px', '10px 16px']"
                fontWeight="600"
                mr="8px"
                :_hover="{ opacity: '0.8' }"
                @click.native="
                  $router.push(
                    `${currentTrack.slug}/challenge/${v.app_type.slug}`
                  )
                "
              >
                {{ v.app_type.status === "done" ? "Completed" : "Start Track" }}
              </CButton>
            </CBox>
            <CBox
              :class="!hideDetail.includes(i) ? 'slide-down' : 'slide-up'"
              overflowY="hidden"
            >
              <CBox
                v-for="(question, index) in v.app_type.questions"
                :key="index"
                d="flex"
                alignItems="center"
                justifyContent="space-between"
                p="8px"
              >
                <CText
                  :fontSize="['12px', '14px', '14px', '14px']"
                  fontWeight="400"
                >
                  {{ question.name }}
                </CText>
              </CBox>
            </CBox>
          </CBox>
        </div>
      </CBox>
      <CBox
        v-if="!isLoading && errorMessage !== 'club not found'"
        :w="['100%', '100%', '25%', '25%']"
      >
        <SidebarDetailTrack :total_join="currentTrack.total_join" />
      </CBox>
    </CBox>
  </CBox>
</template>

<script>
import { CBox } from "@chakra-ui/vue";
import { GET_TRACK_DETAIL } from "@/store/track.module";
import SidebarDetailTrack from "@/components/sidebar/DetailTrack.vue";
import Loading from "vue-loading-overlay";
import BackHeader from "@/components/BackHeader";
import "vue-loading-overlay/dist/vue-loading.css";
import { validationMixin } from "vuelidate";
import "moment-timezone";
import config from "@/ConfigProvider";
import Swal from "sweetalert2";

export default {
  name: "Detail Track",
  components: {
    CBox,
    Loading,
    SidebarDetailTrack,
    BackHeader,
  },
  mixins: [validationMixin],
  data() {
    return {
      baseUrl: config.value("DEVCODE_WEB_URL"),
      isLoading: false,
      isUrlCopied: false,
      hideDetail: [],
      maxDescriptionLength: 200, // Jumlah karakter maksimum untuk deskripsi yang ditampilkan
      showFullDescription: false,
      showDescAssesment: [],
      isBookmark: false,
    };
  },
  async mounted() {
    await this.getData();
  },
  computed: {
    currentTrack() {
      return this.$store.getters.currentTrack || [];
    },
    truncatedDescription() {
      return (
        this.currentTrack.description.slice(0, this.maxDescriptionLength) +
        "..."
      );
    },
  },
  methods: {
    async getData() {
      try {
        const params = {
          slug: this.$route.params.slug,
        };

        await this.$store.dispatch(GET_TRACK_DETAIL, params);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          this.isLoading = false;
          this.errorMessage = error.response.data.message;
          console.error(`Error 400: ${this.errorMessage}`);
          Swal.fire({
            title: "Oops!",
            text: "You don't have access to this page!",
            icon: "error",
          }).then(() => {
            window.history.back();
          });
        }
      } finally {
        this.isLoading = false;
      }
    },
    truncateText(text, maxLength) {
      if (text && text.length > maxLength) {
        return text.slice(0, maxLength) + "...";
      }
      return text;
    },
    checkSlug(data) {
      for (let v of data) {
        if (v.app_type.status === "available") {
          return v.app_type.slug;
        }
      }
      return data[0].app_type.slug;
    },
    handleHideDetail(index) {
      if (this.hideDetail.includes(index)) {
        this.hideDetail = this.hideDetail.filter((item) => item !== index);
      } else {
        this.hideDetail.push(index);
      }
    },
    handleShowDescAssesment(index) {
      if (this.showDescAssesment.includes(index)) {
        this.showDescAssesment = this.showDescAssesment.filter(
          (item) => item !== index
        );
      } else {
        this.showDescAssesment.push(index);
      }
    },
    scrollDown() {
      // Mengakses elemen yang ingin digulir
      const target = this.$refs.scrollTarget;
      // Menggulir ke elemen target
      target.scrollIntoView({ behavior: "smooth", block: "start" });
    },
  },
};
</script>

<style>
.rotate-180 {
  transition: transform 0.3s ease-in-out;
}

.rotate-0 {
  transition: transform 0.3s ease-in-out;
}

.rotate-180 {
  transform: rotate(180deg);
}

.rotate-0 {
  transform: rotate(0);
}
.slide-up {
  animation: slideUp 0.3s ease-in-out forwards;
}

.slide-down {
  animation: slideDown 0.3s ease-in-out forwards;
}

@keyframes slideUp {
  from {
    height: fit-content;
  }
  to {
    height: 0;
  }
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: fit-content;
  }
}
</style>
